<template>
  <helper-input-btn>
    <erp-input
        ref="input"
        @input="__set"
        slot="input"
        simple-border
        v-model="description"
        @keydown.enter="searchTribunal"
        readonly
    >
    </erp-input>
    <a @click="searchTribunal"><i class="erp-icon search min"></i> </a>
    <u-icon name="plus-circle" type="fa" icon-style="light" class="m-l-xs cursor-pointer" @click.native="abrirCadastro" />
  </helper-input-btn>
</template>

<script>
import InputMixin from '../../../../../reuse/input/mixin'
import {HelperInputBtn} from 'uloc-vue-plugin-erp'
import searchTribunal from '../window/search/SearchTribunal'

export default {
  name: 'TribunalInput',
  mixins: [InputMixin],
  props: {
    setTribunal: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      findedData: null,
      description: null,
      defaultTribunal: null
    }
  },
  components: {HelperInputBtn},
  mounted () {
    if (this.setTribunal) {
      const v = this.setTribunal
      this.description = `${v.nome} (${v.uf})`
      this.__updateInternal()
    }
  },
  methods: {
    __updateInternal() {
      this.updateInternal((v) => `${v.nome} (${v.uf})`)
      this.$emit('selecionaTribunal', this.findedData)
    },
    abrirCadastro () {
      // this.windowTribunal()
      // TODO
    },
    searchTribunal
  }
}
</script>
