import {list} from '@/domain/processos/services/tribunal'

const search = function () {
  console.log('click window search tribunal...')
  let window = 'windowSearch'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Buscar Tribunal',
    width: '800',
    height: '600',
    minHeight: '500',
    backdrop: true,
    clickOutside: false,
    windowClass: 'erp-window',
    contentClass: 'overflow-hidden bg-white',
    props: {
      columns: [
        {label: 'ID', value: 'id'},
        {label: 'Nome', value: 'nome'},
        {label: 'Estado', value: 'uf'}
      ],
      fetchData: (terms, filters, done) => {
        console.log('Buscando dados: ', terms, filters, done)
        let rowsPerPage = 100
        list(rowsPerPage, filters.page, '&sortBy=id&descending=desc&search=' + terms)
            .then(({data}) => {
              let filter = function (terms, {field, list}) {
                const token = terms.toLowerCase()
                return list.filter(item => ('' + item[field]).toLowerCase().includes(token))
              }

              let filteredData = filter(terms, {field: 'nome', list: data.result})

              done(filteredData, {
                page: filters.page,
                rowsNumber: data.total, // response.totalRows,
                rowsPerPage: rowsPerPage
              })
            })
            .catch((error) => {
              console.error(error)
            })
      }
    }
  }, () => import('../../../../../layout/window/WindowSearch.vue'))
      .then((wid) => {
        this.$uloc.window.listen(wid, {
          selected: (wid, val) => {
            console.log('Selecionado com sucesso: ', val)
            this.findedData = val
            this.model = val.id
            this.__emit(val.id)
            this.description = `${val.nome} (${val.uf})`
            setTimeout(() => {
              this.$refs.input && this.$refs.input.focus()
            }, 200)
          }
        })
        console.log(wid)
      }) // return wid
}

export default search
